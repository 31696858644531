<template>
  <div>
    <div id="downloadApp">
      <div class="downloadAPPBox">
        <div class="bgBox">
          <img
            src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1654695968676.jpg"
          />
        </div>

        <div class="allPage">
          <div class="appInfo">
            <img class="headImg" src="../imgs/icon/ico-kmh.png" />
            <div class="appName">看漫App</div>
            <div class="appDesc">好玩好看的漫画平台</div>
          </div>
          <!--  -->
          <div class="downBtn">
            <div @click="downLoad">下载看漫App</div>
          </div>

          <!-- 描述 -->
          <div class="descBg">
            <img
              src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1654700771584.jpg"
            />
            <!-- <div class="descBgTip">新用户加入看漫数藏QQ群，最高可抽6666元现金</div> -->
            <!-- <div class="descBgDesc">
            <div>下载看漫App，完成预付款，即刻获得</div>
            <div>神秘藏品空投一份</div>
          </div> -->
            <div class="descVersion2" v-if="!activityId">
              <div>下载看漫App，进入“我的”-“数字藏品”页面，</div>
              <div>超多空投福利免费领</div>
            </div>
            <div class="descVersion2" v-if="activityId">
              <div>你已求赠<span>神秘藏品一份</span>，请下载看漫App，</div>
              <div>进入“我的”-“数字藏品”-“我的藏品”页面领取。</div>
            </div>
          </div>

          <!--  -->
          <div class="handleList">
            <!-- <div class="flexBox">
          <div class="liLeft">
            <img src="../imgs/icon/icon_ewm.png" alt="" srcset="" />
            <div class="liText">扫码注册&nbsp;&nbsp;实名认证</div>
          </div>
          <div class="liRight">
            <img src="../imgs/icon/icon_jtzs.png" alt="" srcset="" />
          </div>
        </div> -->
            <div class="flexBox">
              <div class="liLeft">
                <img src="../imgs/icon/icon_xz.png" alt="" srcset="" />
                <div class="liText">
                  下载&nbsp;&nbsp;
                  <img
                    src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1654699036635.jpg"
                  />
                  &nbsp; 看漫App
                </div>
              </div>
              <div class="liRight">
                <img src="../imgs/icon/icon_jtzs.png" alt="" srcset="" />
              </div>
            </div>
            <div class="flexBox">
              <div class="liLeft">
                <img src="../imgs/icon/icon_wdcp.png" alt="" srcset="" />
                <div class="liText">进入“我的”-“数字藏品”页面</div>
              </div>
              <div class="liRight">
                <img v-if="activityId" src="../imgs/icon/icon_jtzs.png" alt="" srcset="" />
                <img v-if="!activityId" src="../imgs/icon/icon_wczs.png" alt="" srcset="" />
              </div>
            </div>

            <div class="flexBox" v-if="activityId">
              <div class="liLeft">
                <img src="../imgs/icon/icon_wdcp_22.png" alt="" srcset="" />
                <div class="liText">进入“我的藏品”页面</div>
              </div>
              <div class="liRight">
                <img src="../imgs/icon/icon_wczs.png" alt="" srcset="" />
              </div>
            </div>
            <!-- <div class="flexBox">
            <div class="liLeft">
              <img src="../imgs/icon/icon_yfk.png" alt="" srcset="" />
              <div class="liText">
                <div>完成预付款</div>
              </div>
            </div>
            <div class="liRight">
              <img src="../imgs/icon/icon_wczs.png" alt="" srcset="" />
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
import { useRoute } from 'vue-router';
export default {
  name: 'downloadApp',
  setup() {
    const route = useRoute();
    const state = reactive({
      activityId: route.query.activityId
    });

    const downLoad = () => {
      nftUtils.setcnzzRecordByBtn('下载看漫app');
      window.location.href =
        'https://app.321mh.com/app/scheme?pkgname=com.wbxm.icartoon&channeCode=nft&ios_scheme=tkanmanapp%3A%2F%2Fgoto%3Fpage%3Dweb%26url%3Dhttps%253A%252F%252Fcollect.mh51.com%252F&android_schema=tkanmanapp%3A%2F%2Fgoto%3Fpage%3Dweb%26url%3Dhttps%253A%252F%252Fcollect.mh51.com%252F';
    };

    onMounted(() => {});

    return {
      ...toRefs(state),
      downLoad
    };
  }
};
</script>

<style lang="scss" scoped>
.downloadAPPBox {
  padding-bottom: 15px;
}
.bgBox {
  position: relative;
  font-size: 0;
  color: #ffffff;
}

.allPage {
  width: 100%;
  position: absolute;
  top: 50px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

img {
  width: 100%;
}

.appInfo {
  // position: absolute;
  // // bottom: -7px;
  // top: 50%;
  // text-align: center;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.headImg {
  width: 86px;
  height: 86px;

  border-radius: 5px;
}

.appName {
  font-size: 20px;
  font-weight: 500;
  margin: 13px 0 7px 0;
}

.appDesc {
  font-size: 14px;
  font-weight: 400;
}

.downBtn {
  margin-top: 30px;
  text-align: center;

  div {
    margin: 0 auto;
    width: 252px;
    height: 44px;
    line-height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-weight: 500;
    color: #1c172a;
  }
}

.descBg {
  position: relative;
  margin-top: 19px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;

  img {
    width: 100%;
  }

  .descBgTip {
    width: 100%;
    white-space: nowrap;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  .descBgDesc {
    width: 100%;
    position: absolute;
    top: 57px;
    left: 50%;
    transform: translateX(-50%);
  }

  .descVersion2 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > div {
      line-height: 32px;
    }

    span {
      color: #3cdbe8;
    }
  }
}

.handleList {
  margin: 15px 20px 0 20px;
  padding: 6px 0 20px 0;
  border: 1px solid;
  border-image: linear-gradient(152deg, rgba(142, 228, 200, 0.51), rgba(54, 219, 234, 1)) 1 1;
  .flexBox {
    margin-top: 9px;
    padding: 0 13px 0 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    background: linear-gradient(132deg, #1d192c 0%, #3a2f75 100%);
    border-radius: 4px 4px 4px 4px;
  }

  .liLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;

    > img {
      width: 22px;
      height: 22px;
    }

    .liText {
      display: flex;
      align-items: center;
      margin-left: 11px;

      > img {
        width: 16px;
        height: 16px;
      }
    }

    .colText {
      flex-direction: column;
      text-align: left;

      > div {
        width: 100%;
      }

      :last-child {
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .liRight {
    width: 24px;
    height: 24px;
    font-size: 0;
    img {
      width: 100%;
    }
  }
}
</style>
